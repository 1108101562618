import './Leaderboard.css';
import { Avatar } from './../Avatar';
import { useState, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';

const groupTeams = (playerList) => {
  const groupedTeams =
    playerList.length > 0
      ? playerList.reduce((acc, element) => {
          const key = element.team === -1 ? element.id : element.team;
          if (!acc[key]) {
            acc[key] = { players: [], medianScore: 0 };
          }
          acc[key].players.push(element);
          return acc;
        }, {})
      : {};

  Object.keys(groupedTeams).forEach((key) => {
    const scores = groupedTeams[key].players
      .map((player) => player.score)
      .sort((a, b) => a - b);

    groupedTeams[key].medianScore = scores.reduce((acc, val) => acc + val, 0);

    // const mid = Math.floor(scores.length / 2);

    // groupedTeams[key].medianScore =
    //   scores.length % 2 !== 0
    //     ? scores[mid]
    //     : (scores[mid - 1] + scores[mid]) / 2;
  });

  return groupedTeams;
};

const Leaderboard = ({ playerList }) => {
  const [teams, setTeams] = useState({});

  useEffect(() => {
    setTeams(groupTeams(playerList));
  }, [playerList]);

  return (
    <div id="leaderboard">
      <div className="ribbon"></div>
      <table>
        <tbody>
          {Object.values(teams)
            .sort((a, b) => b.medianScore - a.medianScore)
            .flatMap((team, i) => (
              <tr>
                <td className="number">{i + 1}</td>
                {team.players.map((player, index) => (
                  <>
                    <Box
                      sx={{
                        position: 'relative',
                        zIndex: 10,
                      }}
                    >
                      <Avatar
                        bodyIndex={player.avatar.body}
                        eyesIndex={player.avatar.eyes}
                        mouthIndex={player.avatar.mouth}
                        isAdmin={player.isAdmin}
                        hatIndex={player.hat}
                        x={25 + index * 35}
                        y="33"
                        scale="0.3"
                        name={player.name}
                      />
                    </Box>
                  </>
                ))}
                <td className="points">{team.medianScore}p</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
