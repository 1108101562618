import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from './components/Home';
import Lobby from './components/Lobby'
import Terms from './components/Terms';
import Privacy from './components/Privacy'
import Navbar from './components/Navbar';

const theme = createTheme({
    typography: {
        fontFamily: 'Nunito',
        color: 'black'
    },
});

const AppContent = () =>
{

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="bg"></div>
                <div className="bg bg2"></div>
                <div className="bg bg3"></div>

                {/* <Navbar /> */}
                <Routes>
                    <Route path="/terms-of-service" element={<Terms />}></Route>
                    <Route path="/privacy" element={<Privacy />}></Route>
                    <Route path="/" element={<Home />} />
                    <Route path="/lobby/:id" element={<Lobby />} />
                </Routes>
            </ThemeProvider>
        </>
    );
};

const App = () =>
{
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

export default App;