import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Tooltip,
  Divider,
  Menu,
  ToggleButton,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  Send,
  Delete,
  VolumeOff,
  GroupAdd,
  Add,
  ArrowBack,
} from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import Loading from './Loading';
import Leaderboard from './assets/Leaderboard';
import { json, useNavigate, useParams } from 'react-router-dom';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Avatar, Hat } from './../components/Avatar';
import clock from './../assets/lobby/clock.gif';
import gptlogo from './../assets/lobby/gptlogo.svg';

import bubble1 from './../assets/lobby/sfx/bubble1.mp3';
import bubble2 from './../assets/lobby/sfx/bubble2.mp3';
import bubble3 from './../assets/lobby/sfx/bubble3.mp3';
import menu1 from './../assets/lobby/sfx/menu1.mp3';
import menu2 from './../assets/lobby/sfx/menu2.mp3';

import time1 from './../assets/lobby/sfx/time1.mp3';

import { Howl } from 'howler';

const io = require('socket.io-client');

// const hatsValidIndexes = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
//   26, 27, 28, 29, 76, 77, 78, 79,
// ];

const Lobby = () => {
  const [volume, setVolumeState] = useState(0.5);

  const sounds = {
    bubble1: new Howl({ src: [bubble1] }),
    bubble2: new Howl({ src: [bubble2] }),
    bubble3: new Howl({ src: [bubble3] }),
    menu1: new Howl({ src: [menu1] }),
    menu2: new Howl({ src: [menu2] }),
    time1: new Howl({ src: [time1] }),
  };

  const playSound = (id) => {
    if (sounds[id]) {
      sounds[id].play();
    } else {
      console.error(`Sound with id ${id} not found`);
    }
  };

  const stopSound = (id) => {
    if (sounds[id]) {
      sounds[id].stop();
    } else {
      console.error(`Sound with id ${id} not found`);
    }
  };

  const setRate = (id, speed) => {
    if (sounds[id]) {
      sounds[id].rate(speed);
    } else {
      console.error(`Sound with id ${id} not found`);
    }
  };

  const setVolume = (volume) => {
    Object.values(sounds).forEach((sound) => {
      sound.volume(volume);
    });
  };

  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);

  const { id } = useParams();
  const socketRef = useRef(null);

  const [time, setTime] = useState(0);
  const [questionData, setQuestionData] = useState({});

  const [playerList, setPlayerList] = useState([]);
  const [chatBox, setChatBox] = useState([]);

  const [settings, setSettings] = useState({
    players: 8,
    language: 'English',
    subject: 'Random',
    context: '',
    answerTime: 60,
    rounds: 3,
    source: 'AI',
    allowTeams: true,
    allowStrangers: true,
    votingType: 'Players + AI',
  });

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [teamLeader, setTeamLeader] = useState(false);
  const [inTeam, setInTeam] = useState(false);
  const [playerId, setPlayerId] = useState(-1);
  const [isAdmin, setIsAdmin] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);

  const [inputChat, setInputChat] = useState('');

  const [selectedButton, setSelectedButton] = useState(null);
  const [hovered, setHovered] = useState(false);

  const [hasSubmitedResponse, setHasSubmitedResponse] = useState(true);

  const [currentQuestionId, setCurrentQuestionId] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentQuestionType, setCurrentQuestionType] = useState('');
  const [currentQuestionPersonality, setCurrentQuestionPersonality] =
    useState('');

  const [currentTeamAnswers, setCurrentTeamAnswers] = useState([]);

  const [disableChat, setDisableChat] = useState(false);

  const [answerText, setAnswerText] = useState('');
  const [responseText, setResponseText] = useState('');

  const [isVoting, setIsVoting] = useState('');

  const [hasVoted, setHasVoted] = useState('');

  const [votingData, setVotingData] = useState({});

  const [allVoted, setAllVoted] = useState(false);
  const [votingResult, setVotingResult] = useState(null);

  const [qIds, setQIds] = useState([]);

  const navigate = useNavigate();

  const handleSettingChange = (key, value) => {
    setSettings((prev) => {
      const updatedSettings = { ...prev, [key]: value };
      if (socketRef) {
        socketRef.current.emit(
          'updateSettings',
          JSON.stringify(updatedSettings)
        );
      }
      return updatedSettings;
    });
  };

  const handleButtonChange = (event, newSelectedButton) => {
    setSelectedButton(newSelectedButton);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateTeam = () => {
    if (socketRef) socketRef.current.emit('createTeam');
  };

  const handleLeaveTeam = () => {
    if (socketRef) socketRef.current.emit('leaveTeam');
  };

  const handleDisbandTeam = () => {
    if (socketRef) socketRef.current.emit('disbandTeam');
  };

  const handleJoinTeam = (id) => {
    if (socketRef) socketRef.current.emit('joinTeam', id);
  };

  const handleStartGame = () => {
    if (socketRef) socketRef.current.emit('startGame');
  };

  const handleSendChat = () => {
    if (socketRef) {
      if (inputChat.length > 0)
        socketRef.current.emit('chat', JSON.stringify(inputChat.slice(0, 256)));
      setDisableChat(true);
      setInputChat('');
      setTimeout(() => {
        setDisableChat(false);
      }, 2000);
    }
  };

  const handleSubmitResponse = () => {
    if (socketRef) {
      socketRef.current.emit(
        'submitResponse',
        JSON.stringify({
          questionId: currentQuestionId,
          answers: answerText,
          response: responseText,
          inTeam: inTeam,
          isLeader: teamLeader,
        })
      );
    }
  };

  const handleNextQuestion = (e) => {
    if (socketRef) {
      socketRef.current.emit('nextQuestion', JSON.stringify(e));
    }
  };

  const handleVote = (e) => {
    if (socketRef) {
      socketRef.current.emit('voteResponse', JSON.stringify(e));
      setHasVoted(true);
    }
  };

  const handleRestartGame = () => {
    if (socketRef) {
      socketRef.current.emit('restartGame');
    }
  };

  const handleChangeHat = () => {
    if (socketRef) {
      socketRef.current.emit('changeHat');
    }
  };

  const handleChangeHatNext = () => {
    if (socketRef) {
      socketRef.current.emit('changeHat', 'next');
    }
  };

  const handleChangeHatPrev = () => {
    if (socketRef) {
      socketRef.current.emit('changeHat', 'prev');
    }
  };

  useEffect(() => {
    setVolume(volume);
  }, [volume]);

  useEffect(() => {
    if (!socketRef.current)
      socketRef.current = io(
        `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/${id}`,
        {
          auth: { jwt: cookies['jwt'] },
        }
      );
    const socket = socketRef.current;

    if (!socket || socket.connected) return;

    socket.on('createTeam', (arg) => {
      setInTeam(true);
      setTeamLeader(true);
    });

    socket.on('startGame', (arg) => {
      setIsLoading(false);
      setGameStarted(true);
      setGameEnded(false);
    });

    socket.on('endGame', () => {
      stopSound('time1');
      setGameStarted(false);
      setGameEnded(true);
    });

    socket.on('joinTeam', (arg) => {
      setInTeam(true);
      setTeamLeader(false);
    });

    socket.on('leaveTeam', (arg) => {
      setInTeam(false);
      setTeamLeader(false);
    });

    socket.on('updatePlayerSettings', (arg) => {
      setIsLoading(false);
      const receivedSettings = JSON.parse(arg);
      setSettings(receivedSettings);
    });

    socket.on('setLoading', (arg) => {
      setIsLoading(arg);
    });

    socket.on('updateClient', (arg) => {
      setIsLoading(false);

      const playerData = JSON.parse(arg);

      if (!playerData) return;

      setPlayerId(playerData.id);
      setInTeam(playerData.inTeam);
      setIsAdmin(playerData.isAdmin);
      setTeamLeader(playerData.isLeader);
    });

    socket.on('updateGameStatus', (arg) => {
      const gameData = JSON.parse(arg);
      if (!gameData) return;

      setGameStarted(gameData.gameStarted);

      setGameEnded(gameData.gameEnded);

      setHasSubmitedResponse(gameData.hasSubmitedResponse);
      setHasVoted(gameData.hasVoted);

      if (gameData.questionData) {
        setCurrentQuestionId(gameData.questionData.currentQuestionId || '');
        setCurrentQuestion(gameData.questionData.question || '');
        setSelectedButton(null);
        setCurrentQuestionType(gameData.questionData.type || '');

        if (
          gameData.questionData.currentQuestionId &&
          !qIds.includes(gameData.questionData.currentQuestionId)
        ) {
          setQIds((oldQIds) => {
            console.log(qIds, oldQIds);

            return [...oldQIds, gameData.questionData.currentQuestionId];
          });
        }

        setCurrentAnswers(gameData?.questionData?.answers || []);
        setCurrentQuestionPersonality(
          gameData?.questionData?.personality || ''
        );

        setVotingData(gameData?.questionData);
        setIsVoting(gameData?.questionData?.isVoting);
        setAllVoted(gameData?.questionData?.allVoted);

        if (gameData.votingResult) setVotingResult(gameData.votingResult);
      }

      if (gameData.playerData) {
        setHasSubmitedResponse(gameData.playerData.hasSubmitted);
        setHasVoted(gameData.playerData.hasVoted);
      }

      if (gameData.playerData && gameData.questionData.providedAnswers) {
        const answerData =
          gameData.questionData.providedAnswers.find(
            (x) => x.player.id === gameData.playerData.id
          ) || null;
        if (answerData) setResponseText(answerData.response);
      }
    });

    socket.on('updateTime', (arg) => {
      if (arg > 0) {
        setRate('time1', 1 + (60 - arg) / 99);
      }
      setTime(arg);
    });

    socket.on('startVoting', (arg) => {
      stopSound('time1');
      setIsVoting(true);
      setVotingData(JSON.parse(arg));
    });

    socket.on('updateQuestion', (arg) => {
      playSound('bubble1');
      stopSound('time1');
      playSound('time1');
      setSelectedButton(null);
      const receivedData = JSON.parse(arg);
      setHasSubmitedResponse(false);
      setIsVoting(false);
      setHasVoted(false);
      setAllVoted(false);
      setVotingResult([]);
      setVotingData({});
      setResponseText('');
      setAnswerText('');
      setCurrentTeamAnswers([]);

      setCurrentQuestionId(receivedData.id);
      setCurrentQuestion(receivedData.question);
      setCurrentQuestionType(receivedData.type);

      if (receivedData.id && !qIds.includes(receivedData.id)) {
        setQIds((oldQIds) => {
          console.log(qIds, oldQIds);

          return [...oldQIds, receivedData.id];
        });
      }

      setCurrentAnswers(receivedData.answers || []);
      setCurrentQuestionPersonality(receivedData?.personality || '');
    });

    socket.on('teamSubmitResponse', (arg) => {
      const receivedData = JSON.parse(arg);

      const answerData = {
        answer: receivedData.data.answers || receivedData.data.response,
        player: receivedData.player,
      };

      setCurrentTeamAnswers((previousData) => [...previousData, answerData]);
    });

    socket.on('updatePlayerList', (arg) => {
      const playerList = [];
      Array.from(JSON.parse(arg)).forEach((element) => {
        playerList.push({
          id: element[1].id,
          name: element[1].username,
          isAdmin: element[1].isAdmin,
          inTeam: element[1].inTeam,
          leader: element[1].isLeader,
          team: element[1].whoseTeam,
          avatar: element[1].avatar,
          submit: element[1].hasSubmitted,
          score: element[1].score,
          hat: element[1].hat,
        });
      });
      setPlayerList(playerList);
    });

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      navigate('/');
    });

    socket.on('chat', (arg) => {
      playSound('bubble2');
      const chatBox = JSON.parse(arg);
      setChatBox(chatBox);
    });

    socket.on('gameStarting', () => {
      setIsLoading(true);
    });

    socket.on('updateVotes', (arg) => {
      stopSound('time1');
      const votes = JSON.parse(arg);
      setVotingResult(votes);
      setAllVoted(true);
    });
  }, [socketRef]);

  const groupTeams = (playerList) => {
    return playerList.length > 0
      ? playerList
          .filter((x) => x.team != -1)
          .reduce((acc, element) => {
            if (!acc[element.team]) {
              acc[element.team] = [];
            }
            acc[element.team].push(element);
            return acc;
          }, {})
      : {};
  };

  const renderPlayers = (playerList) => {
    return (
      <>
        {playerList.map((element, index) => {
          if (!element.inTeam) {
            return (
              <Paper
                key={index}
                elevation={2}
                sx={{
                  p: 1,
                  borderRadius: 2,
                  marginBottom: 1,
                  backgroundColor:
                    gameStarted && !gameEnded && !isVoting
                      ? element.submit
                        ? 'lightgreen'
                        : 'pink'
                      : '',
                }}
              >
                <ListItem key={index}>
                  <Box display="flex" alignItems="center" width="100%">
                    <Avatar
                      name={element.username}
                      bodyIndex={element.avatar.body}
                      eyesIndex={element.avatar.eyes}
                      mouthIndex={element.avatar.mouth}
                      isAdmin={element.isAdmin}
                      hatIndex={element.hat}
                      scale="0.3"
                    />
                    <Box
                      marginLeft="30px"
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Typography
                        fontWeight="bold"
                        fontSize="18px"
                        color={element.id == playerId ? 'blue' : 'black'}
                      >
                        {element.name}
                      </Typography>
                      <Typography
                        fontWeight="bold"
                        fontSize="13px"
                        color={element.id == playerId ? 'black' : 'black'}
                      >
                        {element.score}p
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </Paper>
            );
          }
          return null;
        })}
      </>
    );
  };

  const renderTeams = (playerList) => {
    const teams = groupTeams(playerList);

    return (
      <>
        {Object.keys(teams).map((team, index) => (
          <div key={index}>
            <Paper
              elevation={2}
              sx={{ p: 2, borderRadius: 2, marginBottom: 1 }}
            >
              {/* <Typography color="black"></Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
              ></Box> */}
              <List>
                {teams[team]
                  .sort((a, b) => b.leader - a.leader)
                  .map((player, idx) => (
                    <ListItem
                      key={idx}
                      sx={{
                        marginTop: idx === 0 ? 0 : 2,
                        borderRadius: 1,
                        backgroundColor: gameStarted
                          ? player.submit
                            ? 'lightgreen'
                            : 'pink'
                          : '',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          name={player.username}
                          bodyIndex={player.avatar.body}
                          eyesIndex={player.avatar.eyes}
                          mouthIndex={player.avatar.mouth}
                          hatIndex={player.hat}
                          isAdmin={player.isAdmin}
                          scale="0.3"
                        />

                        <Box
                          marginLeft="30px"
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Typography
                            fontWeight="bold"
                            fontSize="18px"
                            color={player.id == playerId ? 'blue' : 'black'}
                            sx={{ flexGrow: 1 }}
                          >
                            {player.name}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        marginLeft="auto"
                        fontWeight="bold"
                        fontSize="13px"
                        color={player.id == playerId ? 'black' : 'black'}
                      >
                        {player.score}p
                      </Typography>
                    </ListItem>
                  ))}
              </List>

              {!inTeam && !gameStarted && (
                <Button
                  sx={{ marginTop: 2 }}
                  onClick={() => {
                    handleJoinTeam(team);
                  }}
                >
                  Join Team
                </Button>
              )}
            </Paper>
          </div>
        ))}
      </>
    );
  };

  return (
    <Box>
      <Loading open={isLoading} />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        <Box sx={{ alignSelf: 'flex-start' }}>
          <Button
            startIcon={<ArrowBack />}
            onClick={() => {
              navigate('/');
              window.location.reload();
            }}
            sx={{ color: 'black' }}
          >
            Back
          </Button>
        </Box>

        {/* <img src="logo.png" alt="logo" style={{ marginBottom: '20px' }} /> */}

        <Grid
          container
          spacing={2}
          mt={2}
          sx={{ width: { xs: '100%', sm: '75%' } }}
        >
          <Grid item xs={12} md={3}>
            {!gameEnded && (
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h6" fontFamily="Nunito" gutterBottom>
                  Leaderboard
                </Typography>

                <List>
                  {renderPlayers(playerList)}

                  {renderTeams(playerList)}

                  {!inTeam && !gameStarted && !gameEnded && (
                    <Button
                      disabled={!settings.allowTeams}
                      onClick={() => {
                        handleCreateTeam();
                      }}
                      variant="contained"
                      color="warning"
                      fullWidth
                      style={{ marginTop: '20px' }}
                    >
                      CREATE TEAM
                    </Button>
                  )}

                  {inTeam && !teamLeader && !gameStarted && !gameEnded && (
                    <Button
                      onClick={() => {
                        handleLeaveTeam();
                      }}
                      variant="contained"
                      color="warning"
                      fullWidth
                      style={{ marginTop: '20px' }}
                    >
                      LEAVE TEAM
                    </Button>
                  )}

                  {inTeam && teamLeader && !gameStarted && !gameEnded && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        width: '100%',
                        gap: '8px',
                      }}
                    >
                      <Button
                        onClick={() => handleChangeHatPrev()}
                        variant="outlined"
                      >
                        &lt;
                      </Button>

                      <Button
                        onClick={() => handleChangeHat()}
                        variant="outlined"
                        fullWidth
                      >
                        Random
                      </Button>

                      <Button
                        onClick={() => handleChangeHatNext()}
                        variant="outlined"
                      >
                        &gt;
                      </Button>
                    </div>
                  )}

                  {inTeam && teamLeader && !gameStarted && !gameEnded && (
                    <Button
                      onClick={() => {
                        handleDisbandTeam();
                      }}
                      variant="contained"
                      color="warning"
                      fullWidth
                      style={{ marginTop: '20px' }}
                    >
                      DISBAND TEAM
                    </Button>
                  )}
                </List>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {!gameStarted && !gameEnded && (
              <Paper elevation={3} style={{ padding: '16px' }}>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Players</InputLabel>
                  <Select
                    label="Players"
                    disabled={!isAdmin}
                    value={settings.players}
                    onChange={(e) =>
                      handleSettingChange('players', e.target.value)
                    }
                  >
                    {[...Array(19)].map((_, i) => (
                      <MenuItem key={i + 2} value={i + 2}>
                        {i + 2}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    disabled={!isAdmin}
                    label="Language"
                    value={settings.language}
                    onChange={(e) =>
                      handleSettingChange('language', e.target.value)
                    }
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    disabled={!isAdmin}
                    label="Subject"
                    value={settings.subject}
                    onChange={(e) =>
                      handleSettingChange('subject', e.target.value)
                    }
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    disabled={!isAdmin}
                    label="Context (optional instructions for the AI)"
                    value={settings.context}
                    onChange={(e) =>
                      handleSettingChange('context', e.target.value)
                    }
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel>Answer Time</InputLabel>
                  <Select
                    label="Answer Time"
                    disabled={!isAdmin}
                    value={settings.answerTime}
                    onChange={(e) =>
                      handleSettingChange('answerTime', e.target.value)
                    }
                  >
                    {[...Array(6)].map((_, i) => (
                      <MenuItem key={i} value={(i + 1) * 10}>
                        {(i + 1) * 10}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel>Rounds</InputLabel>
                  <Select
                    label="Rounds"
                    disabled={!isAdmin}
                    value={settings.rounds}
                    onChange={(e) =>
                      handleSettingChange('rounds', e.target.value)
                    }
                  >
                    {[...Array(9)].map((_, i) => (
                      <MenuItem key={i + 2} value={i + 2}>
                        {i + 2}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel>Source</InputLabel>
                  <Select
                    label="Source"
                    disabled={!isAdmin}
                    value={settings.source}
                    onChange={(e) =>
                      handleSettingChange('source', e.target.value)
                    }
                  >
                    <MenuItem value="AI">AI</MenuItem>
                    <MenuItem value="External">External</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel>Voting Type</InputLabel>
                  <Select
                    label="Voting Type"
                    disabled={!isAdmin}
                    value={settings.votingType}
                    onChange={(e) =>
                      handleSettingChange('votingType', e.target.value)
                    }
                  >
                    <MenuItem value="Players + AI">Players + AI</MenuItem>
                    <MenuItem value="Only Players">Only Players</MenuItem>
                  </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      disabled={!isAdmin}
                      checked={settings.allowTeams}
                      onChange={(e) =>
                        handleSettingChange('allowTeams', e.target.checked)
                      }
                    />
                    <Typography variant="body1" component="span">
                      Allow Teams
                    </Typography>
                  </Box>
                </FormControl>

                <Button
                  disabled={!isAdmin}
                  onClick={() => {
                    handleStartGame();
                  }}
                  variant="contained"
                  color="success"
                  fullWidth
                  style={{ marginTop: '20px' }}
                >
                  Start
                </Button>
              </Paper>
            )}

            {gameStarted && !gameEnded && !isVoting && (
              <>
                <Paper
                  elevation={3}
                  style={{ padding: '16px', textAlign: 'center' }}
                >
                  <Box position="relative" display="inline-block">
                    <Box component="img" src={clock} alt="Clock" />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="black"
                    >
                      <Typography
                        position="absolute"
                        marginTop="-100%"
                        variant="h6"
                      >
                        {time}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography color="black">
                    Question {qIds.length} of {settings.rounds}
                  </Typography>
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    marginTop: '10px',
                    padding: '16px',
                    textAlign: 'center',
                  }}
                >
                  <Typography color="black" variant="h5">
                    {currentQuestion}
                  </Typography>

                  {currentQuestionType === 'personality' && (
                    <Typography color="black">
                      Required personality: {currentQuestionPersonality}
                    </Typography>
                  )}
                </Paper>

                <Paper
                  elevation={3}
                  style={{
                    marginTop: '10px',
                    padding: '16px',
                    textAlign: 'center',
                  }}
                >
                  <Stack>
                    <ToggleButtonGroup
                      disabled={hasSubmitedResponse}
                      orientation="vertical"
                      value={selectedButton}
                      exclusive
                      onChange={handleButtonChange}
                      aria-label="text alignment"
                    >
                      {currentAnswers.map((value, index) => {
                        return (
                          <ToggleButton
                            onClick={() => setAnswerText(value)}
                            value={index}
                            aria-label="left aligned"
                            sx={{
                              backgroundColor:
                                selectedButton === index ? 'green' : 'initial',
                              color:
                                selectedButton === index ? 'white' : 'black',
                              '&:hover': {
                                backgroundColor:
                                  selectedButton === index
                                    ? 'darkgreen'
                                    : 'lightblue',
                              },
                            }}
                          >
                            <Typography color="black">{value}</Typography>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>

                    {currentQuestionType !== 'checkbox' && (
                      <TextField
                        disabled={hasSubmitedResponse}
                        value={responseText}
                        onChange={(e) => setResponseText(e.target.value)}
                      ></TextField>
                    )}
                    <Button
                      disabled={hasSubmitedResponse}
                      onClick={() => {
                        handleSubmitResponse();
                        setHasSubmitedResponse(true);
                      }}
                      variant="contained"
                      color="success"
                      style={{ marginTop: '20px' }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Paper>
              </>
            )}

            {gameStarted && !gameEnded && isVoting && (
              <>
                <Paper
                  elevation={3}
                  style={{ padding: '16px', textAlign: 'center' }}
                >
                  <Typography color="black">{votingData.question}</Typography>

                  {votingData.type === 'personality' && (
                    <Typography color="black">
                      Required personality: {votingData.personality}
                    </Typography>
                  )}
                  {votingData.type === 'checkbox' ? (
                    <>
                      <List>
                        {votingData?.answers?.map((value, index) => (
                          <ListItem key={index}>
                            {votingData.providedAnswers.filter(
                              (p) => p.player.id === playerId
                            ).length > 0 ? (
                              <Typography
                                fontWeight={
                                  votingData.providedAnswers.filter(
                                    (p) => p.player.id === playerId
                                  )?.[0].answer === value
                                    ? 'bold'
                                    : 'normal'
                                }
                                color={
                                  value === votingData.correct ? 'green' : 'red'
                                }
                                sx={{
                                  textDecoration:
                                    votingData.providedAnswers.filter(
                                      (p) => p.player.id === playerId
                                    )?.[0].answer === value
                                      ? 'underline'
                                      : 'normal',
                                }}
                              >
                                {value}
                              </Typography>
                            ) : (
                              <Typography
                                color={
                                  value === votingData.correct ? 'green' : 'red'
                                }
                              >
                                {value}
                              </Typography>
                            )}
                            {votingData.providedAnswers
                              .filter((e) => e.answer === value)
                              .map((e, i) => (
                                <>
                                  <ListItemText
                                    index={i}
                                    sx={{ position: 'absolute' }}
                                  >
                                    <Avatar
                                      name={e.player.username}
                                      bodyIndex={e.player.avatar.body}
                                      eyesIndex={e.player.avatar.eyes}
                                      mouthIndex={e.player.avatar.mouth}
                                      isAdmin={e.player.isAdmin}
                                      hatIndex={e.player.hat}
                                      scale="0.2"
                                      x={e.answer.length * 10 + i * 28}
                                      y="-4"
                                    />
                                  </ListItemText>
                                </>
                              ))}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ) : (
                    <>
                      {votingData && (
                        <List>
                          {votingData.providedAnswers
                            .filter(
                              (e) =>
                                e.player.socketId !== socketRef.current.id ||
                                allVoted
                            )
                            .sort(
                              (a, b) =>
                                -1 + (Math.floor(Math.random() * 999) % 3)
                            )
                            .map((value, index) => (
                              <Stack marginTop="10px">
                                {allVoted && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Avatar
                                      name={value.player.username}
                                      bodyIndex={value.player.avatar.body}
                                      eyesIndex={value.player.avatar.eyes}
                                      mouthIndex={value.player.avatar.mouth}
                                      isAdmin={value.player.isAdmin}
                                      hatIndex={value.player.hat}
                                      scale="0.17"
                                      y="-8"
                                      x="40"
                                      sx={{ position: 'absolute', left: 0 }}
                                    />
                                    <Typography
                                      color="black"
                                      sx={{ marginLeft: '60px' }}
                                    >
                                      {value.player.username} has answered:
                                    </Typography>
                                  </Box>
                                )}
                                <Paper
                                  sx={{
                                    width: '100%',
                                    border:
                                      value.player.id === playerId
                                        ? '3px solid orange'
                                        : '0px',
                                    p: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    fontSize="17px"
                                    fontWeight="bold"
                                    color={
                                      value.player.id === playerId
                                        ? 'black'
                                        : 'black'
                                    }
                                  >
                                    {value.response}
                                    {allVoted &&
                                      votingResult
                                        .find(
                                          (x) => x[0] === value.player.id
                                        )?.[1]
                                        ?.map((p, i) => (
                                          <Avatar
                                            name={p.username}
                                            bodyIndex={p.avatar.body}
                                            eyesIndex={p.avatar.eyes}
                                            mouthIndex={p.avatar.mouth}
                                            isAdmin={p.isAdmin}
                                            hatIndex={p.hat}
                                            scale="0.2"
                                            y="5"
                                            x={5 + i * 25}
                                          />
                                        ))}
                                  </Typography>

                                  {allVoted &&
                                    votingData.currentGPTOpinion &&
                                    value.player.id ===
                                      votingData.currentGPTOpinion.playerId && (
                                      <Box
                                        sx={{
                                          width: '7%',
                                          marginLeft: 'auto',
                                          marginRight: '5%',
                                        }}
                                        component="img"
                                        src={gptlogo}
                                      ></Box>
                                    )}
                                  <Button
                                    disabled={allVoted || hasVoted}
                                    onClick={() =>
                                      handleVote({
                                        playerId: value.player.id,
                                        questionId: votingData.id,
                                      })
                                    }
                                    variant="contained"
                                    sx={{
                                      backgroundColor: '#3ad63a',
                                      '&:hover': {
                                        backgroundColor: '#1dde1d',
                                      },
                                    }}
                                  >
                                    <Typography>😍</Typography>
                                  </Button>
                                </Paper>
                              </Stack>
                            ))}
                        </List>
                      )}
                    </>
                  )}
                  {allVoted && votingData.currentGPTOpinion ? (
                    <Typography color="black">
                      GPT-3's opinion on the voted reponse:{' '}
                      {votingData.currentGPTOpinion.opinion}
                    </Typography>
                  ) : (
                    isAdmin &&
                    votingData.type !== 'checkbox' && (
                      <Typography color="black">
                        Wait for all the players to vote!
                      </Typography>
                    )
                  )}
                  <Stack
                    gap="10px"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {votingData.type !== 'checkbox' && !allVoted && (
                      <Button
                        onClick={() =>
                          handleVote({ playerId: -1, questionId: -1 })
                        }
                        variant="contained"
                        sx={{ backgroundColor: allVoted ? 'green' : 'orange' }}
                        disabled={hasVoted}
                      >
                        Skip voting
                      </Button>
                    )}

                    {isAdmin && (
                      <Button
                        onClick={() =>
                          handleNextQuestion({
                            forceVoting:
                              !allVoted && votingData.type !== 'checkbox',
                          })
                        }
                        variant="contained"
                        sx={{ backgroundColor: allVoted ? 'green' : 'orange' }}
                        disabled={!isAdmin}
                      >
                        {!allVoted && votingData.type !== 'checkbox'
                          ? 'SKIP VOTING ROUND FOR EVERYBODY'
                          : 'NEXT QUESTION'}
                      </Button>
                    )}
                  </Stack>
                </Paper>
              </>
            )}

            {gameEnded && (
              <>
                <Paper
                  elevation={3}
                  style={{
                    padding: '16px',
                    textAlign: 'center',
                    maxHeight: '800px',
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                  }}
                >
                  <Leaderboard playerList={playerList} />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="10%"
                    mt="50px"
                  >
                    <Button
                      onClick={() => handleRestartGame()}
                      variant="contained"
                      sx={{ backgroundColor: 'green' }}
                      disabled={!isAdmin}
                    >
                      RESTART GAME
                    </Button>
                  </Box>
                  {/* <List>
                {playerList.sort((a, b) => b.score - a.score).map((element, index) => (
                    <ListItem key={index}>
                        {element.name} score: {element.score}
                    </ListItem>
                ))}
            </List> */}
                </Paper>
              </>
            )}

            {isAdmin && (
              <Paper
                elevation={3}
                style={{
                  padding: '16px',
                  marginTop: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography color="black" variant="body1">
                  Invite your friends
                </Typography>
                <Tooltip
                  color="black"
                  title={window.location.href}
                  open={hovered}
                  onOpen={() => setHovered(true)}
                  onClose={() => setHovered(false)}
                >
                  <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                    Hover to see invite link
                  </Typography>
                </Tooltip>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} style={{ padding: 6 }}>
              <Box
                sx={{
                  maxHeight: '30vw',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <List sx={{ padding: 0 }}>
                  {chatBox.map((element, index) => (
                    <ListItem key={index} sx={{ padding: 0 }}>
                      {element.type === 'JoinEvent' && (
                        <ListItemText
                          primary={`${element.sender} ${element.message}`}
                          primaryTypographyProps={{
                            style: {
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                              color: 'green',
                              background:
                                index % 2 == 0 ? '#ECECEC' : '#FFFFFF',
                            },
                          }}
                        />
                      )}

                      {element.type === 'ReconnectEvent' && (
                        <ListItemText
                          primary={`${element.sender} ${element.message}`}
                          primaryTypographyProps={{
                            style: {
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                              color: 'orange',
                              background:
                                index % 2 == 0 ? '#ECECEC' : '#FFFFFF',
                            },
                          }}
                        />
                      )}

                      {element.type === 'LeaveEvent' && (
                        <ListItemText
                          primary={`${element.sender} ${element.message}`}
                          primaryTypographyProps={{
                            style: {
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                              color: 'red',
                              background:
                                index % 2 == 0 ? '#ECECEC' : '#FFFFFF',
                            },
                          }}
                        />
                      )}

                      {element.type === 'MessageEvent' && (
                        <ListItemText
                          primary={`${element.sender} said: ${element.message}`}
                          primaryTypographyProps={{
                            style: {
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                              background:
                                index % 2 == 0 ? '#ECECEC' : '#FFFFFF',
                            },
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box mt={2} display="flex">
                <TextField
                  disabled={disableChat}
                  value={inputChat}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) handleSendChat();
                  }}
                  onChange={(e) => {
                    setInputChat(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                  placeholder={
                    !disableChat ? 'Type a message...' : 'Wait few seconds...'
                  }
                />
                <IconButton
                  onClick={() => {
                    handleSendChat();
                  }}
                  color="primary"
                >
                  <Send />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Lobby;
