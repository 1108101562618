import React, { useState, useEffect } from 'react';
import {
  Container,
  IconButton,
  Accordion,
  Slider,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Paper,
  Divider,
  CircularProgress,
  Modal,
  Stack,
} from '@mui/material';
import axios from 'axios';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import { Avatar } from './Avatar';
import leftArrow from './../assets/lobby/avatar/leftArrow.gif';
import rightArrow from './../assets/lobby/avatar/rightArrow.gif';
import randomize from './../assets/lobby/avatar/randomize.gif';
import VolumeUp from '@mui/icons-material/VolumeUp';
import logo from './../assets/home/logo.png';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import avatar from './../assets/lobby/avatar_placeholder.png';
import { ArrowRight, ArrowLeft } from '@mui/icons-material';
import { GPTButton, Button_Styled1 } from './assets/Buttons';
import YouTubeCarousel from './../components/YoutubeCarousel';

const Home = () => {
  const [currentPressedButtonIndex, setCurrentPressedButtonIndex] = useState(0);
  const [avatarBody, setAvatarBody] = useState(0);
  const [avatarEyes, setAvatarEyes] = useState(0);
  const [avatarMouth, setAvatarMouth] = useState(0);
  const [volume, setVolume] = useState(30);

  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const [isLogged, setIsLogged] = useState(false);
  const [loggedUsername, setLoggedUsername] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [historyData, setHistoryData] = useState({});
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const [language, setLanguage] = useState('English');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setShowAlert(false);
    setAlertMessage('');
  };

  const navigate = useNavigate();

  function mod(n, m) {
    return ((n % m) + m) % m;
  }

  useEffect(() => {
    handleLoad();
    if (!isLogged) setCurrentPressedButtonIndex(0);
  }, [isLogged]);

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/register`,
        {
          username: username,
          password: password,
          confirmPassword: confirmPassword,
        }
      );

      setAlertMessage('You registered succesfully!');
      setAlertSeverity('success');
      setShowAlert(true);

      setCookie('jwt', response.data.jwt);

      setTimeout(() => {
        setOpenModal(false);
      }, 1000);
    } catch (error) {
      setAlertMessage(error.response.data);
      setAlertSeverity('error');
      setShowAlert(true);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/login`,
        {
          username: username,
          password: password,
        }
      );

      setAlertMessage('Succesfully logged in.');
      setAlertSeverity('success');
      setIsLogged(true);
      setShowAlert(true);

      setCookie('jwt', response.data.jwt);

      setTimeout(() => {
        setOpenModal(false);
      }, 1000);
    } catch (error) {
      setAlertMessage(error.response.data);
      setAlertSeverity('error');
      setShowAlert(true);
    }
  };

  const handlePlay = async () => {
    try {
      const authToken = cookies['jwt'];
      if (authToken) {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/random`,
          {
            jwt: authToken,
          }
        );
        navigate(`/lobby/${response.data.lobbyId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreatePrivateLobby = async () => {
    try {
      const authToken = cookies['jwt'];
      if (authToken) {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/create`,
          {
            jwt: authToken,
          }
        );

        navigate(`/lobby/${response.data.lobbyId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoad = async () => {
    try {
      const authToken = cookies['jwt'];
      if (authToken) {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/authentificate`,
          {
            jwt: authToken,
          }
        );

        setIsLogged(true);
        setLoggedUsername(response.data.username);
        setAvatar(response.data.avatar);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadHistory = async () => {
    try {
      const authToken = cookies['jwt'];
      if (authToken) {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/history/`,
          {
            jwt: authToken,
          }
        );
        setHistoryData(response.data);
        setHasLoadedHistory(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAvatar = async () => {
    try {
      const authToken = cookies['jwt'];
      if (authToken) {
        const response = await axios.put(
          `${process.env.REACT_APP_HOST_NAME}:${process.env.REACT_APP_SERVER_PORT}/api/updateAvatar`,
          {
            jwt: authToken,
            avatar: { avatarBody, avatarEyes, avatarMouth },
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (avatarBody != 0 || avatarEyes != 0 || avatarMouth != 0) {
      updateAvatar();
    }
  }, [avatarBody, avatarEyes, avatarMouth]);

  const handleSetAvatarBody = (value, max) => {
    value = mod(value, max);
    setAvatarBody(value);
  };

  const handleSetAvatarEyes = (value, max) => {
    value = mod(value, max);
    setAvatarEyes(value);
  };

  const handleSetAvatarMouth = (value, max) => {
    value = mod(value, max);
    setAvatarMouth(value);
  };

  const setAvatar = (avatar) => {
    setAvatarBody(avatar.body);
    setAvatarEyes(avatar.eyes);
    setAvatarMouth(avatar.mouth);
  };

  const generateAvatar = () => {
    setAvatarBody(generateNumber(25));
    setAvatarEyes(generateNumber(56));
    setAvatarMouth(generateNumber(50));
    updateAvatar();
  };

  const generateNumber = (maxValue) => {
    return Math.floor(Math.random() * (maxValue + 1));
  };

  const renderModalContent = () => {
    if (isRegistering) {
      return (
        <Box sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Typography variant="h6">Register</Typography>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {showAlert && <Alert severity={alertSeverity}> {alertMessage}</Alert>}
          <Button
            onClick={handleRegister}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Register
          </Button>
          <Button
            onClick={() => {
              setIsRegistering(false);
              setShowAlert(false);
            }}
            fullWidth
            variant="text"
            sx={{ mt: 2 }}
          >
            Already have an account?
          </Button>
        </Box>
      );
    } else {
      return (
        <Box sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Typography variant="h6">Login</Typography>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {showAlert && <Alert severity={alertSeverity}> {alertMessage}</Alert>}
          <Button
            onClick={() => {
              handleLogin();
            }}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Login
          </Button>
          <Button fullWidth variant="text" sx={{ mt: 2 }}>
            Forgot Password?
          </Button>
          <Button
            onClick={() => {
              setIsRegistering(true);
              setShowAlert(false);
            }}
            fullWidth
            variant="text"
            sx={{ mt: 2 }}
          >
            Don't have an account? Register
          </Button>
        </Box>
      );
    }
  };

  return (
    <Container>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
          }}
        >
          {renderModalContent()}
        </Box>
      </Modal>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          mb={2}
          sx={{ width: { xs: '20vw', md: '10vw' }, marginTop: '-10%' }}
        >
          <img src={logo} alt="Logo" style={{ width: '100%' }} />
        </Box>
        <Box
          backgroundColor="#2C2F33"
          sx={{
            width: { xs: '95vw', sm: '80vw', md: '70vw', lg: '60vw' },
            height: { xs: '75vh', sm: '60vh', md: '50vh' },
            borderRadius: 5,
          }}
        >
          <Box
            backgroundColor="#2C2FFFF33"
            sx={{
              borderBottom: 1,
              height: 'auto',
              p: 2,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            }}
          >
            <Button_Styled1
              onClick={() => setCurrentPressedButtonIndex(0)}
              style="custom-btn btn-2"
              text="HOME"
            />
            {isLogged && (
              <Button_Styled1
                onClick={() => setCurrentPressedButtonIndex(1)}
                style="custom-btn btn-2"
                text="GAME HISTORY"
              />
            )}
            {/* {isLogged && (<Button_Styled1 onClick={() => setCurrentPressedButtonIndex(2)} style="custom-btn btn-2" text="SETTINGS" />)} */}

            {!isLogged ? (
              <>
                <Button_Styled1
                  text="Login"
                  style="custom-btn btn-9"
                  onClick={() => {
                    setIsRegistering(false);
                    handleOpenModal();
                  }}
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 'auto' }}
                ></Button_Styled1>
                <Button_Styled1
                  text="Register"
                  style="custom-btn btn-9"
                  onClick={() => {
                    setIsRegistering(true);
                    handleOpenModal();
                  }}
                ></Button_Styled1>
              </>
            ) : (
              <>
                <Button_Styled1
                  style="custom-btn btn-10"
                  text="LOGOUT"
                  onClick={() => {
                    removeCookie('jwt');
                    setLoggedUsername('');
                    setIsLogged(false);
                  }}
                  variant="contained"
                  color="secondary"
                  sx={{ marginLeft: 'auto' }}
                ></Button_Styled1>
              </>
            )}
          </Box>

          {currentPressedButtonIndex == 0 && (
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              sx={{ height: '85%' }}
            >
              <Box
                sx={{
                  height: { xs: '30%', sm: '100%' },
                  width: { xs: '100%', sm: '30%', md: '40%' },
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack sx={{ position: 'relative' }}>
                    <Box
                      zIndex="100"
                      onClick={() => {
                        generateAvatar();
                      }}
                      component="img"
                      src={randomize}
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '50%',
                        transform: {
                          xs: 'translate(-50%, 380%) scale(1.5)',
                          md: 'translate(-50%, -50%) scale(1.5)',
                        },
                        '&:hover': {
                          transform: 'translate(-50%, -50%) scale(1.7)',
                        },
                      }}
                    />
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'scale(1.3)',
                        marginTop: { xs: '50%', sm: 'auto' },
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={4}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box
                            onClick={() => {
                              handleSetAvatarEyes(avatarEyes - 1, 56);
                            }}
                            component="img"
                            src={leftArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                          <Box
                            onClick={() => {
                              handleSetAvatarMouth(avatarMouth - 1, 50);
                            }}
                            component="img"
                            src={leftArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                          <Box
                            onClick={() => {
                              handleSetAvatarBody(avatarBody - 1, 26);
                            }}
                            component="img"
                            src={leftArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                        </Grid>
                        <Avatar
                          bodyIndex={avatarBody}
                          eyesIndex={avatarEyes}
                          mouthIndex={avatarMouth}
                          isAdmin={false}
                          y="-50"
                        />

                        <Grid
                          item
                          xs={4}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          marginLeft="75px"
                        >
                          <Box
                            onClick={() => {
                              handleSetAvatarEyes(avatarEyes + 1, 56);
                            }}
                            component="img"
                            src={rightArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                          <Box
                            onClick={() => {
                              handleSetAvatarMouth(avatarMouth + 1, 50);
                            }}
                            component="img"
                            src={rightArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                          <Box
                            onClick={() => {
                              handleSetAvatarBody(avatarBody + 1, 26);
                            }}
                            component="img"
                            src={rightArrow}
                            sx={{
                              height: '25%',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.2)',
                                filter: 'invert(1)',
                              },
                              zIndex: 100,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {!isLogged && (
                      <Typography
                        color="red"
                        sx={{ mt: 0, textAlign: 'center' }}
                      >
                        You need to be logged in.
                      </Typography>
                    )}

                    {/* <Typography
                      sx={{
                        textAlign: 'center',
                        transform: 'translate(0%, 0%)',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                      }}
                    >
                      <a href="#/terms-of-service">Terms of Service</a>-
                      <a href="#/privacy">Privacy Policy</a>
                    </Typography> */}
                  </Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '10px',
                  height: '100%',
                  width: { xs: '100%', sm: '40%', md: '20%' },
                }}
              >
                <Stack>
                  <Button_Styled1
                    text="Play"
                    style="custom-btn btn-9"
                    disabled={!isLogged}
                    variant="contained"
                    color="primary"
                    onClick={() => handlePlay()}
                    sx={{ padding: '30px', marginTop: '100px' }}
                  ></Button_Styled1>
                  <Button_Styled1
                    text="Custom"
                    style="custom-btn btn-9"
                    disabled={!isLogged}
                    onClick={() => {
                      handleCreatePrivateLobby();
                    }}
                    variant="outlined"
                    color="primary"
                  ></Button_Styled1>
                </Stack>
              </Box>
              <Box
                sx={{
                  height: '100%',
                  width: { xs: '100%', sm: '30%', md: '40%' },
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <GPTButton sx={{ width: '50px' }} />
              </Box>
            </Box>
          )}

          {currentPressedButtonIndex == 1 && (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', sm: 'column' }}
              sx={{ height: '78%' }}
            >
              <Button_Styled1
                sx={{ display: hasLoadedHistory ? 'none' : 'block' }}
                style="custom-btn btn-4"
                text="LOAD HISTORY"
                onClick={() => {
                  handleLoadHistory();
                }}
                variant="contained"
                color="secondary"
              ></Button_Styled1>

              <Box sx={{ height: '100%', overflow: 'auto' }}>
                {historyData.length > 0 && (
                  <List>
                    {historyData
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((e, i) => {
                        const parsedData = JSON.parse(e.data);
                        return (
                          <ListItem key={i}>
                            <Paper
                              sx={{
                                p: 2,
                                m: 1,
                                width: '100%',
                                backgroundColor: '#d9c6c5',
                              }}
                            >
                              <Typography fontWeight="bold" variant="h6">
                                {e.date}
                              </Typography>
                              <Typography
                                fontWeight="bold"
                                fontSize="24px"
                                color="black"
                                variant="body1"
                              >
                                Subject: {parsedData?.subject?.subject}
                              </Typography>
                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                  <Typography color="black">
                                    Show Game Details
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography variant="h6">
                                    Questions and Answers:
                                  </Typography>
                                  {parsedData?.qData?.generatedQuestions.map(
                                    (question, qIndex) => (
                                      <Box key={qIndex} sx={{ mb: 2 }}>
                                        <Paper
                                          elevation={3}
                                          sx={{ backgroundColor: '#ede6e6' }}
                                        >
                                          <Typography
                                            mt="1"
                                            fontWeight="bold"
                                            color="black"
                                            variant="body1"
                                          >
                                            {question.question}
                                          </Typography>
                                          {question.type === 'checkbox' &&
                                            question.answers.map(
                                              (answer, aIndex) => (
                                                <React.Fragment key={aIndex}>
                                                  {question.providedAnswers.map(
                                                    (pAnswer, pIndex) => (
                                                      <Box
                                                        key={pIndex}
                                                        sx={{
                                                          display: 'flex',
                                                          flexDirection:
                                                            'column',
                                                        }}
                                                      >
                                                        {pAnswer.answer ===
                                                          answer && (
                                                          <Avatar
                                                            bodyIndex={
                                                              pAnswer.player
                                                                .avatar.body
                                                            }
                                                            eyesIndex={
                                                              pAnswer.player
                                                                .avatar.eyes
                                                            }
                                                            mouthIndex={
                                                              pAnswer.player
                                                                .avatar.mouth
                                                            }
                                                            isAdmin={
                                                              pAnswer.player
                                                                .isAdmin
                                                            }
                                                            scale="0.17"
                                                            y="6"
                                                            x={20 + pIndex * 16}
                                                          />
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                  <Typography
                                                    mt="1"
                                                    key={`typography-${aIndex}`}
                                                    color={
                                                      answer ===
                                                      question.correct
                                                        ? 'green'
                                                        : 'red'
                                                    }
                                                  >
                                                    {answer}
                                                  </Typography>
                                                </React.Fragment>
                                              )
                                            )}
                                          {(question.type === 'response' ||
                                            question.type === 'personality') &&
                                            question.providedAnswers.map(
                                              (answer, aIndex) => (
                                                <List>
                                                  <ListItem>
                                                    <Avatar
                                                      bodyIndex={
                                                        answer.player.avatar
                                                          .body
                                                      }
                                                      eyesIndex={
                                                        answer.player.avatar
                                                          .eyes
                                                      }
                                                      mouthIndex={
                                                        answer.player.avatar
                                                          .mouth
                                                      }
                                                      isAdmin={
                                                        answer.player.isAdmin
                                                      }
                                                      scale="0.2"
                                                      y="-5"
                                                    />
                                                    <Typography
                                                      fontWeight="bold"
                                                      marginLeft="20px"
                                                      color="black"
                                                      sx={{
                                                        wordBreak: 'break-word',
                                                        whiteSpace: 'normal',
                                                      }}
                                                    >
                                                      {answer.player.username}{' '}
                                                      answered:{' '}
                                                      {answer.response ===
                                                      '' ? (
                                                        <span
                                                          style={{
                                                            fontWeight: '0',
                                                          }}
                                                        >
                                                          You didn't answer!
                                                        </span>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            fontWeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {answer.response}
                                                        </span>
                                                      )}
                                                    </Typography>
                                                  </ListItem>
                                                </List>
                                              )
                                            )}
                                        </Paper>
                                      </Box>
                                    )
                                  )}
                                  <Paper
                                    sx={{
                                      maxHeight: '400px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Typography
                                      ml="10px"
                                      mt="10px"
                                      variant="h6"
                                    >
                                      Chat history:
                                    </Typography>
                                    <List
                                      sx={{
                                        maxHeight: '350px',
                                        overflowY: 'auto',
                                      }}
                                    >
                                      {parsedData?.chat?.map(
                                        (chat, cIndex) =>
                                          chat.type === 'MessageEvent' && (
                                            <ListItem
                                              sx={{
                                                backgroundColor:
                                                  cIndex % 2 === 0
                                                    ? '#edeced'
                                                    : '#fffefe',
                                              }}
                                              key={cIndex}
                                            >
                                              <Typography color="black">
                                                {chat.sender} said:{' '}
                                                {chat.message}
                                              </Typography>
                                            </ListItem>
                                          )
                                      )}
                                    </List>
                                  </Paper>
                                </AccordionDetails>
                              </Accordion>
                            </Paper>
                          </ListItem>
                        );
                      })}
                  </List>
                )}
              </Box>
            </Box>
          )}

          {currentPressedButtonIndex == 2 && (
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              sx={{ height: '85%' }}
            >
              <>
                {currentPressedButtonIndex == 2 && (
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    sx={{ height: '85%', alignItems: 'center' }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: '100%', mt: 2 }}
                    >
                      <IconButton>
                        <VolumeUp />
                      </IconButton>
                      <Slider
                        value={volume}
                        onChange={handleVolumeChange}
                        aria-labelledby="volume-slider"
                        sx={{ ml: 2, width: '80%' }}
                      />
                    </Box>
                  </Box>
                )}
              </>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
