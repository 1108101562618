import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const Privacy = () =>
{
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Card sx={{ width: '80%', maxWidth: '800px', p: 4 }}>
                <CardContent>
                    <Typography variant="h4" align="center" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography color="black" align="center">
                        At Quizzus.io, we respect your privacy and are committed to protecting any personal information you provide through our website, https://quizzus.io (referred to as "we," "us," or "our"). This Privacy Policy outlines what data we collect, how we use it, and the conditions under which we may share it. It only applies to information collected through this website.
                        <br/> <br/>
                        Non-Personal Information: We may collect non-personal information like browser type, language preference, and the date and time of your visit to improve our services.
                        Personal Information: If you create an account or make a purchase, we collect personal details such as your username, email, and financial information.
                        <br/> <br/>
                        Security While we take steps to secure your data, no online method is completely secure. We strive to protect your information, but we cannot guarantee its absolute security.
                        <br/> <br/>
                        Cookies We use cookies to enhance your experience, track usage, and display relevant ads. You can disable cookies in your browser settings, though some site features may not work properly.
                        <br/> <br/>
                        Third-Party Links Our site may contain links to third-party websites. We are not responsible for their privacy practices, so we encourage you to review their policies.
                        <br/> <br/>
                        Advertisements Third-party ad providers may use cookies to serve targeted ads based on your interests. You can opt out of these practices via the Network Advertising Initiative: http://www.networkadvertising.org/managing/opt_out.asp.
                        <br/> <br/>
                        User Content Quizzus.io is not responsible for any user-generated content such as quizzes or comments.
                        <br/> <br/>
                        Changes to the Privacy Policy We may update this Privacy Policy from time to time. Please check back regularly for updates. Your continued use of our site signifies acceptance of any changes.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Privacy;