import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Box } from '@mui/material';

const YouTubeCarousel = ({ videos, sx}) => {
    return (
        <Carousel>
            {videos.map((video, index) => (
                <Paper key={index}>
                    <Box
                        component="iframe"
                        src={`https://www.youtube.com/embed/${video}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        sx={sx}
                    />
                </Paper>
            ))}
        </Carousel>
    );
};

export default YouTubeCarousel;