import React from 'react';
import { Grid, Box, Tooltip } from '@mui/material';

import leftArrow from './../assets/lobby/avatar/leftArrow.gif';
import rightArrow from './../assets/lobby/avatar/rightArrow.gif';

import avatarBody from './../assets/lobby/avatar/color_atlas.gif';
import avatarEyes from './../assets/lobby/avatar/eyes_atlas.gif';
import mouthAtlas from './../assets/lobby/avatar/mouth_atlas.gif';
import specialAtlas from './../assets/lobby/avatar/special_atlas.gif';

import crown from './../assets/lobby/avatar/crown.gif';

const selectBody = (index) => {
  index = index % 26;
  const i = Math.floor(index / 10);
  const j = index % 10;

  return {
    position: 'absolute',
    height: '49px',
    width: '48px',
    backgroundImage: `url(${avatarBody})`,
    transform: 'scale(2.5)',
    backgroundPosition: `${-j * 48}px ${-i * 49}px`,
  };
};

const selectEyes = (index) => {
  index = index % 57;
  const i = Math.floor(index / 10);
  const j = index % 10;

  return {
    position: 'absolute',
    height: '48px',
    width: '48px',
    backgroundImage: `url(${avatarEyes})`,
    transform: 'scale(2.5)',
    backgroundPosition: `${-j * 48}px ${-i * 48}px`,
  };
};

const selectMouth = (index) => {
  index = index % 51;
  const i = Math.floor(index / 10);
  const j = index % 10;

  return {
    position: 'absolute',
    height: '48px',
    width: '48px',
    backgroundImage: `url(${mouthAtlas})`,
    transform: 'scale(2.5)',
    backgroundPosition: `${-j * 48}px ${-i * 48}px`,
  };
};

const selectHat = (index) => {
  index = index % 100;
  const i = Math.floor(index / 10);
  const j = index % 10;

  return {
    position: 'absolute',
    height: '80px',
    width: '80px',
    marginTop: '-20px',
    backgroundImage: `url(${specialAtlas})`,
    transform: 'scale(2.5)',
    backgroundPosition: `${-j * 80}px ${-i * 80}px`,
  };
};

const Hat = (args) => {
  return (
    <>
      {(args.hatIndex || args.hatIndex === 0) && (
        <Box
          sx={{
            transform: `scale(${args.scale})`,
            marginLeft: `${args.x}%`,
            marginTop: `${args.y}%`,
          }}
        >
          <Box sx={selectHat(args.hatIndex)} />
        </Box>
      )}
    </>
  );
};

const Avatar = (args) => {
  return (
    <Tooltip title={args.name}>
      <Grid
        position="absolute"
        item
        xs={4}
        display="flex"
        justifyContent="center"
        sx={{
          transform: `scale(${args.scale})`,
          marginLeft: `${args.x}px`,
          marginTop: `${args.y}px`,
        }}
      >
        <Box sx={selectBody(args.bodyIndex)} />

        <Box sx={selectEyes(args.eyesIndex)} />

        <Box sx={selectMouth(args.mouthIndex)} />

        {(args.hatIndex || args.hatIndex === 0) && (
          <Box sx={selectHat(args.hatIndex)} />
        )}

        {args.isAdmin && (
          <Box
            sx={{
              position: 'absolute',
              height: '24px',
              width: '24px',
              backgroundImage: `url(${crown})`,
              transform: 'scale(2.5)',
              marginLeft: '-72px',
              marginTop: '-48px',
              opacity: 0.75,
              pointerEvents: args.isAdmin ? 'auto' : 'none',
            }}
          />
        )}
      </Grid>
    </Tooltip>
  );
};

export { Avatar, Hat };
