import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const Terms = () =>
{
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Card sx={{ width: '80%', maxWidth: '800px', p: 4 }}>
                <CardContent>
                    <Typography variant="h4" align="center" gutterBottom>
                        Terms & Conditions
                    </Typography>
                    <Typography color="black" align="center">
                        These Terms and Conditions govern your use of the Quizzus.io website and its related services. By accessing or using the site (https://quizzus.io), you agree to comply with these Terms. If you do not agree, you must not use the site.
                        <br/> <br/>
                        Changes to Terms We may modify these Terms at any time. Please check regularly for updates, as continued use of the site indicates your acceptance of any changes.
                        <br/> <br/>
                        Definitions
                        <br/> <br/>
                        Quizzus.io: Refers to the website Quizzus.io, including all related games and services.
                        User Content: Any content not created by Quizzus.io, such as quizzes, usernames, or chat messages.
                        We, Us, Our: Refers to Quizzus.io.
                        You: Refers to users of the Quizzus.io website and services.
                        <br/> <br/>
                        Cookies We use cookies to enhance your experience. By using Quizzus.io, you consent to our cookie policy as outlined in our Privacy Policy.
                        <br/> <br/>
                        License All intellectual property on Quizzus.io belongs to us or our licensors. You may view or print material from the site for personal use, but may not republish or reuse any content without permission.
                        <br/> <br/>
                        User Content Quizzus.io is not responsible for user-generated content such as quizzes or comments. Users are responsible for ensuring that their content is lawful and does not infringe upon others' rights. We reserve the right to remove any content deemed inappropriate.
                        <br/> <br/>
                        Age Restrictions Quizzus.io is not intended for users under 13. Users under 13 should seek guardian consent before using the site. We are not liable for any consequences arising from underage use.
                        <br/> <br/>
                        External Links We are not responsible for third-party content accessed through links on Quizzus.io. Clicking on external links is at your own risk.
                        <br/> <br/>
                        Bots and External Tools Using cheats, bots, or unauthorized software is prohibited and may result in termination of your account.
                        <br/> <br/>
                        Availability We strive to maintain site availability but do not guarantee it. Quizzus.io may be temporarily unavailable due to maintenance or other reasons.
                        <br/> <br/>
                        Advertisements Third-party advertisements may appear on Quizzus.io. We are not responsible for their content or any damage caused by clicking on them.
                        <br/> <br/>
                        Refund Policy Refunds for purchases of virtual currency are available within 7 days of purchase, provided the currency is unused. If you have shared your account or refunded without notifying us, your account may be closed.
                        <br/> <br/>
                        Intellectual Property Rights You may not use the Quizzus.io name, logo, or website design without permission. Any unauthorized use will result in termination of your access and potential legal action.
                        <br/> <br/>
                        Limitation of Liability We are not liable for any damages resulting from your use of Quizzus.io, including communication with other users or any loss resulting from viruses or technical issues.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Terms;